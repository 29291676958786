<template>
  <div>

    <div v-if="inventory" class="viewContainer inventory">
      <h1>{{ $t('inventory.inventories') }} / {{ inventory.name }} / {{ title }}</h1>
      <InventoryHead ref="inventoryHead" :inventoryFinished="inventory.inventoryState === 'finished'" @addWine="addWine"
        :progress="progress" :rowIndex="currentRowIndex" @closeSidepanel="closeSidepanel" @openSidepanel="openSidepanel"/>
      <InventoryContent ref="inventoryContent" @changeStateBackToOpen="handleChangeStateBackToOpen"
        :inventoryState="inventory.inventoryState" @updateInventoryWineTable="updateInventoryWineInTable" v-if="filteredWines && !loading"
        :wines="filteredWines" @changedRowIndex="handleChangedRowIndex" @buttonTextInventoryOn="buttonTextInventoryOn" @buttonTextInventoryOff="buttonTextInventoryOff"/>
        <LoadingAnimation v-if="loading" />

      <CreateWinesModal ref="createWinesModal" @addedNewVenueWines="handleAddedNewVenueWines" />

    </div>
  </div>

</template>
<script>

import InventoryContent from '../components/inventory/InventoryContent.vue';
import DataService from '@/services/StrapiService.js';
import InventoryHead from '../components/inventory/InventoryHead.vue';
import DataMappingService from '@/services/DataMappingService.js';
import { filterInventoryVenueWines } from '@/utils/functions';
import CreateWinesModal from '../components/modals/addWine/CreateWinesModal.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import { processVenueWineIds } from '@/utils/functions';

export default {
  name: 'InventoryWines',

  data() {
    return {
      inventory: this.$route.params.inventory,
      loading: false,
      inventoryVenueWineState: {
        checked: 0,
        total: 0
      },
      filteredWines: [],
      progress: {
        value: 0,
        max: 0
      },
      filterString: '',
      currentRowIndex: null,
      title: this.$t(this.$route.params.titleKey)
    }
  },
  created() {
    this.filterString = this.$route.params.filterString;
    this.loadInventory(this.$route.params.id, this.filterString);
  },
  mounted() {

  },
  props: {
    id: {
      type: Number,
      default: 0
    },
  },

  components: {
    InventoryContent,
    InventoryHead,
    CreateWinesModal,
    LoadingAnimation

  },

  methods: {
    buttonTextInventoryOn() {
      this.$refs.inventoryHead.buttonTextInventoryOn();
    },
    buttonTextInventoryOff() {
      this.$refs.inventoryHead.buttonTextInventoryOff();
    },
    closeSidepanel() {
      this.$refs.inventoryContent.closeSidepanel();
    },
    openSidepanel() {
      this.$refs.inventoryContent.openSidepanelFromButton();
    },
    handleChangedRowIndex(rowIndex) {
      this.currentRowIndex = rowIndex;
    },
    addWine() {
      this.$refs.createWinesModal.showModal();
    },
    handleChangeStateBackToOpen() {
      const inventory = {
        inventoryState: 'open'
      }
      DataService.updateInventory(this.inventory.id, inventory)
        .then(() => {
          this.loadInventory(this.inventory.id, this.$route.params.filterString);
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateInventoryWineInTable(inventoryVenueWine) {
      console.log('updateInventoryWineInTable :>> ', inventoryVenueWine);
      const index = this.filteredWines.findIndex(inventory => inventory.id === inventoryVenueWine.id);
      this.filteredWines[index].actualAmount = inventoryVenueWine.actualAmount;
      this.filteredWines[index].sellingPrice = inventoryVenueWine.sellingPrice;
      this.filteredWines[index].shelf = inventoryVenueWine.shelf;
      this.filteredWines[index].inventoryVenueWineState = inventoryVenueWine.inventoryVenueWineState;
      this.filteredWines[index].purchasePrice = inventoryVenueWine.purchasePrice;
      this.filteredWines[index].duplicate = inventoryVenueWine.duplicate;
    },
    handleAddedNewVenueWines(venueWineIds) {
      processVenueWineIds(
        venueWineIds,
        this.inventory.id,
        this.loadInventory,
        this.$route,
        this.$store,
        this.$t
      );
      this.loadInventory(this.inventory.id, this.filterString);
    },
    loadInventory(inventoryId, filterString) {
      this.loading = true;
      DataService.getInventory(inventoryId)
        .then(response => {
          this.inventory = DataMappingService.mapGetInventoryResponse(response.data.data);
          const filteredWines = filterInventoryVenueWines(this.inventory.inventoryVenueWines);
          this.filteredWines = filteredWines[filterString]
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    }
  }
}
</script>
<style scoped></style>